import React, {useState, useEffect} from "react"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import listStyleBlue from "../../images/project-list-style-blue.svg"
import listStyleRed from "../../images/project-list-style-red.svg"

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from '../../styles/device'

const ProjectContentTextTextSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    min-height: fit-content;
    width: 100%;
    position: relative;
    font-family: ${fonts.poppins};

    margin-top: 80px;

    @media ${device.tablet}{
        margin-top: 60px;
    }
`

const ProjectContentTextTextBackground = styled.div`
    position: absolute;
    width: 50%;
    height: 100%;
    left: ${({order}) => order === "image_text" ? "0" : "inherit" };
    right: ${({order}) => order === "image_text" ? "inherit" : "0" };
    background-color: ${({family}) => family.includes("design") ? colors.primaryLight : colors.secondaryLight};
    z-index: -1;

    @media ${device.tablet}{
        width: calc(100vw + 60px);
        left: -30px;
        right: -30px;
    }

    @media ${device.phone}{
        width: calc(100vw + 40px);
        left: -20px;
        right: -20px;
    }
`


const ProjectContentTextTextContainer = styled.div`
    max-width: 1440px;
    
    margin: auto;
    padding: 80px 8.3333%;

    @media ${device.tablet}{
        padding: 40px 30px 0;
    }

    @media ${device.phone}{
        padding: 40px 20px 0;
    }
`

const ProjectContentTextTextRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    
    margin: 0 -10px;

    @media ${device.tablet}{
        flex-direction: column;
    }
`

const ProjectContentTextTextText = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: ${colors.tertiary};
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
    padding: 0 10px;
    order: ${({order}) => order === "image_text" ? "2" : "1" };

    * {
        margin: 0;
        padding: ${({order}) => order === "image_text" ? "0 0 0 50px" : "0 50px 0 0" };
    }

    h2 {
        font-size: 33px;
        font-weight: bold;
        letter-spacing: -0.71px;
        line-height: 37px;
        margin-top: 0;
    }

    h2 + p {
        margin-top: 24px;
    }

    ul {
        list-style: none;
        padding: ${({order}) => order === "image_text" ? "0 50px 0 0" : "0 0 0 50px" };
    }

    li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    li + li {
        margin-top: 24px;
    }

    li::before {
        content: "";
        min-width: 25px;
        min-height: 25px;
        background: url(${({twoTextFamily}) => twoTextFamily ==="design" ? listStyleRed : listStyleBlue }) center center no-repeat;
        margin-right: 12px;
        margin-top: 4px;
    }

    @media ${device.tablet}{
        width: inherit;
        position: relative;

        *, ul {
            padding: 0;
        }

        + div {
            margin-top: 40px;
            padding: 40px 10px;
        }
    }
`

const ProjectContentTextText = ({twoTextOrder, twoTextFamily, twoTextText1, twoTextText2}) => {

    const [windowWidth, setWindowWidth] = useState(0)

    const thrs = 0.25

    const [ref, entry] = useIntersect({
      threshold: thrs
    });
  
    const [show, setShow] = useState(false)
  
    const props = useSpring({
        from: {
            opacity: 0, 
            transform: `translateY(200px)`,
          },
          to: {
            opacity: show ? 1 : 0,
            transform: `translateY(${show ? 0 : 200}px)`
          },
          onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
    })

    const AnimatedProjectContentTextTextContainer = animated(ProjectContentTextTextContainer)

    const propsBackground = useSpring({
        from: {
            opacity: 0, 
            transform: `translateX(${ twoTextOrder === "image_text" ? '-200px' : '200px' })`,
          },
          to: {
            opacity: show ? 1 : 0,
            transform: `translateX(${show ? 0 : twoTextOrder === "image_text" ? '-200px' : '200px'}px)`
          },
          onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
    })

    const AnimatedProjectContentTextTextBackground = animated(ProjectContentTextTextBackground)

    useEffect( () => {

        if(typeof window !== 'undefined') {
            setWindowWidth(window.innerWidth);
            window.addEventListener("resize", () => {
              setWindowWidth(window.innerWidth)
            })
          }

    }, [] )


    return (
        <ProjectContentTextTextSection ref={ref} order={twoTextOrder} family={twoTextFamily}>

            {windowWidth >= deviceSize.desktop && <AnimatedProjectContentTextTextBackground order={twoTextOrder} family={twoTextFamily} style={propsBackground} />}

            <AnimatedProjectContentTextTextContainer style={props}>

                <ProjectContentTextTextRow>

                    <ProjectContentTextTextText order={twoTextOrder} family={twoTextFamily} dangerouslySetInnerHTML={{__html: twoTextText1}} />
                    <ProjectContentTextTextText order={twoTextOrder} family={twoTextFamily}>
                        <div dangerouslySetInnerHTML={{__html: twoTextText2}} />
                        {windowWidth < deviceSize.desktop && <AnimatedProjectContentTextTextBackground order={twoTextOrder} family={twoTextFamily} style={propsBackground} />}
                    </ProjectContentTextTextText>
                
                </ProjectContentTextTextRow>

            </AnimatedProjectContentTextTextContainer>

        </ProjectContentTextTextSection>
    )
}

export default ProjectContentTextText