import React from "react"
import styled from "styled-components"

import ProjectContentFullWidth from "./projectContentFullWidth"
import ProjectContentTextMedia from "./projectContentTextMedia"
import ProjectContentTextText from "./projectContentTextText"
import ProjectContentSlider from "./projectContentSlider"


const ProjectContentBlock = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:last-child{
        padding-bottom: 0;

        &::before {
            bottom: 0;
        }
    }
`

const ProjectContent = props => {

    const { flexibleContents } = props

    return (

        <ProjectContentBlock id="projectContent">

            {/*
                Loop to call all the existing flexible contents of a project page
                Keys hare created randomly
            */}

            {flexibleContents.map((item, index) => {

                if(item.__typename === "WordPressAcf_project_content_slider")
                    return <ProjectContentSlider
                        key={'ProjectContentSlider' + index}
                        sliderText={item.project_content_slider_text}
                        sliderFamily={item.project_content_slider_family}
                        sliderSelector={item.project_content_slider_content.project_content_slider_content_selector}
                        sliderGallery={item.project_content_slider_content.project_content_slider_content_gallery}
                    />
                    

                if(item.__typename === "WordPressAcf_project_content_full_width")
                    return <ProjectContentFullWidth
                        key={'ProjectContentFullWidth' + index}
                        fullWidthText={item.project_content_full_width_text}
                        fullWidthTextBottom={item.project_content_full_width_text_bottom}
                        fullWidthFamily={item.project_content_full_width_media.project_content_full_width_media_selector}
                        fullWidthContent={item.project_content_full_width_media.project_content_full_width_media_items}
                        fullWidthColor={item.project_content_full_width_media.project_content_full_width_media_color}
                    />

                if(item.__typename === "WordPressAcf_project_content_text_media")
                    return <ProjectContentTextMedia
                        key={'ProjectContentTextMedia' + index}
                        textMediaOrder={item.project_content_text_media_order_selector}
                        textMediaFamily={item.project_content_text_media_family}
                        textMediaTitle={item.project_content_text_media_title}
                        textMediaDescription={item.project_content_text_media_description}
                        textMediaMedia={item.project_content_text_media_media}
                    />

                if(item.__typename === "WordPressAcf_project_content_two_text")
                    return <ProjectContentTextText
                        key={'ProjectContentTextText' + index}
                        twoTextOrder={item.project_content_two_text_order_selector}
                        twoTextFamily={item.project_content_two_text_family}
                        twoTextText1={item.project_content_two_text_text_1}
                        twoTextText2={item.project_content_two_text_text_2}
                    />

                return null
            })}

        </ProjectContentBlock>
    )
}

export default ProjectContent;