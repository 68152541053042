import React, {Fragment} from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import device from '../styles/device'

const Mobile = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: calc(375px * 0.75);
    margin: ${props => props.margins || "auto"};

    padding: 9px;
    border-radius: 26px;
    background-color: #F5F5F5;
    box-shadow: 0px 2px 25px 0px rgba(0,0,0,0.21);

    .deviceContainer {
        border-radius: 21px;
        overflow: hidden;
    }

    @media ${device.tablet}{
        max-width: 272px;
    }

    @media ${device.phone}{
        margin: 0 auto;
    }
`

const Tablet = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: calc(768px / 1.5);
    margin: ${props => props.margins || "auto"};

    padding: 40px 15px;
    background: linear-gradient(${props => props.color_top ? props.color_top : "#fff"}, ${props => props.color_bottom ? props.color_bottom : "#fff"} );
    border: 5px solid #F5F5F5;
    border-radius: 26px;
    box-shadow: 0px 2px 25px 0px rgba(0,0,0,0.21);

    @media ${device.tablet}{
        max-width: calc(768px / 2);
    }
`

const Window = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: auto;
    max-width: 768px;
    margin: ${props => props.margins || "auto"};
    position: relative;

    padding: 30px 0 0;
    background-color: #F5F5F5;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 2px 25px 0px rgba(0,0,0,0.21);

    @media ${device.tablet}{
        max-width: calc(768px * 0.75);
    }

    .WindowPart1,
    .WindowPart2,
    .WindowPart3 {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #E2E2E2;
        border-radius: 10px;
        top: 10px;
        z-index: 1;
    }

    .WindowPart1 {
        left: 10px
    }

    .WindowPart2 {
        left: 30px
    }

    .WindowPart3 {
        left: 50px
    }
`

const Large = styled.div`
    width: 100%;
    box-sizing: border-box;
    max-width: 1280px;
    min-width: ${({deviceMinWidth}) => deviceMinWidth ? "none" : "768px"};

    
    margin: ${props => props.margins || "auto"};

    box-shadow: 0px 2px 36px 0px rgba(0,0,0,0.1);
`

const Device = ({ imageData, deviceType, color_top, color_bottom, margins, deviceMinWidth }) => {
        
    return(
        
        <Fragment>    
            {
                deviceType === "mobile" ? 
                <Mobile>
                    <div className="deviceContainer">
                        <Img alt="" fluid={imageData.localFile.childImageSharp.fluid}/>
                    </div>
                </Mobile>
                :
                deviceType === "tablet" ?
                <Tablet>
                    <div className="deviceContainer">
                        <Img alt="" fluid={imageData.localFile.childImageSharp.fluid}/>
                    </div>
                </Tablet>
                :
                deviceType === "window" ?
                <Window>
                    <div className="deviceContainer">
                        <Img alt="" fluid={imageData.localFile.childImageSharp.fluid}/>
                    </div>
                    <div className="WindowPart1"></div>
                    <div className="WindowPart2"></div>
                    <div className="WindowPart3"></div>
                </Window>
                :
                deviceType === "large" ?
                <Large>
                    <div className="deviceContainer">
                        <Img alt="" fluid={imageData.localFile.childImageSharp.fluid}/>
                    </div>
                </Large>
                : deviceType === "none" ?
                    <Img alt="" fluid={imageData.localFile.childImageSharp.fluid}/>
                :
                null
            }         
        </Fragment>
    )
    
}

export default Device