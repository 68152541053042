import React, { useState } from "react"
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import device from '../../styles/device'

import Device from "../device"


const ProjectContentFullWidthImgItemContainer = styled.li`
  margin: 0;
  ${ ({family, size}) => family !== "none" ? "width: " + 100/size + "%;" : "width: 100%;"}
  padding: 0 10px;
  box-sizing: border-box;

  & + li {
      margin-top: ${ ({family}) => family === "none" ? "40px" : "0"}
  }

  @media ${device.phone}{
      width: 100%;
      
      & + li {
          margin-top: 40px
      }
  }
`

const ProjectContentFullWidthImgItem = ({fullWidthContent, fullWidthFamily, item}) => {

    const thrs = 0.25

    const [ref, entry] = useIntersect({
        threshold: thrs
    });

    const [show, setShow] = useState(false)

    const props = useSpring({
        from: {
            opacity: 0, 
            transform: `translateY(200px)`,
            },
            to: {
            opacity: show ? 1 : 0,
            transform: `translateY(${show ? 0 : 200}px)`
            },
            onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
    })
    
  return (

    <ProjectContentFullWidthImgItemContainer ref={ref} size={fullWidthContent.length} family={fullWidthFamily}>
        <animated.div style={props}>
            <Device imageData={item} deviceType={fullWidthFamily} />
        </animated.div>
    </ProjectContentFullWidthImgItemContainer>
    
    )
}

export default ProjectContentFullWidthImgItem