import React from 'react'
import styled from "styled-components"
import {Link} from "gatsby"

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device from "../../styles/device"

import ArrowVostok from "../../images/binaria_logotip_2019.png"




const ProjectNavContainer = styled.div`
    padding: 80px 8.3333%;

    @media ${device.phone} {
        padding: 60px 20px;
    }
`

const ProjectNavBlock = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
`

const ProjectNavLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${fonts.poppins};

    :hover {
          color: ${({direction}) => direction === "center" && colors.primaryDark};
    }
    :active {
        color: ${({direction}) => direction === "center" && colors.primaryLight};
    }
    
    opacity: 1;
    transition: .2s;

    &:hover {
        opacity: ${({direction}) => direction !== "center" && .4};
    }

    ${ ({direction}) => direction === "center" ? "color: " + colors.primary : "color: " + colors.tertiary + "!important" };
    ${ ({direction}) => direction === "center" ? "text-decoration: underline" : "text-decoration: none" };
    ${ ({direction}) => direction === "center" && "position: absolute"};
    ${ ({direction}) => direction === "center" && "margin: 0 auto" };

    ${ ({direction}) => direction === "left" ? "order: 1" : ( direction === "center" ? "order: 2" : "order: 3") };

    ${({direction}) => direction === "left" && "margin-right: auto"};
    ${({direction}) => direction === "right" && "margin-left: auto"};

    img {
        ${ ({direction}) => direction === "left" ? "transform: rotate(-90deg)" : ( direction === "center" ? "" : "transform: rotate(90deg)") };
        ${({direction}) => direction === "right" && "margin-left: 20px"};
        ${({direction}) => direction === "left" && "margin-right: 20px"};
        width: 25px;
        height: 25px;
    }

    @media ${device.tablet} {
        span {
            display: none;
        }
    }
`


const ProjectNav = ({prev, next, path, navProjects: {left, center, right}}) => (
    <ProjectNavContainer>

        <ProjectNavBlock>

            {prev !== null && <ProjectNavLink direction="left" to={path + prev.slug}>

                <img src={ArrowVostok} alt="previous"/>
                <span>{left}</span>

            </ProjectNavLink>}

            <ProjectNavLink direction="center" to={path} next={next} prev={prev}>
                {center}
            </ProjectNavLink>

            
            {next !== null && <ProjectNavLink direction="right" to={path + next.slug}>

                <span>{right}</span>
                <img src={ArrowVostok} alt="next"/>

            </ProjectNavLink>}
            
        </ProjectNavBlock>

    </ProjectNavContainer>
);


export default ProjectNav;
