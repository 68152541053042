import React from "react"
import { graphql } from "gatsby"
import {Helmet} from "react-helmet"

import Layout from "../layout"
import ProjectHeader from "../components/project/projectHeader"
import ProjectContent from "../components/project/projectContent"
import ProjectNav from "../components/project/projectNav"
import ContactCta from "../components/contactCta"

//dataLang:
import langDataEs from '../data/languages/es'
import langDataCa from '../data/languages/ca'
import langDataEn from '../data/languages/en'

import defaultRRSSImage from '../images/binaria_generica_rrss.jpg'


const projectTemplate = ({data, pageContext}) => {

  //Create two constant to each type of content

  const header = data.wordpressWpProject.acf.project_header;
  const content = data.wordpressWpProject.acf.project_content_project;
  const yoast_meta = data.wordpressWpProject.yoast_meta;

  const {sideMenu, cookies, footer, projects: {navProjects}, contactBanner: {normal} } = (pageContext.lang === "es" && langDataEs) || (pageContext.lang === "ca" && langDataCa) || (pageContext.lang === "en" && langDataEn)
  

  const rrssImage = data.wordpressWpProject.featured_media ? data.wordpressWpProject.featured_media.localFile.publicURL : defaultRRSSImage
  const featuredImage = `${data.site.siteMetadata.siteUrl}${rrssImage}`

  return(

    <Layout sideMenu={sideMenu} cookies={cookies} footer={footer} negative={header.project_header_text_color}>
      <Helmet>
          <title>{yoast_meta.yoast_wpseo_title}</title>
          <meta name="description" content={yoast_meta.yoast_wpseo_metadesc} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://binaria.com/proyectos/${data.slug}`} />
          <meta property="og:title" content={yoast_meta.yoast_wpseo_title} />
          <meta property="og:description" content={yoast_meta.yoast_wpseo_metadesc} />
          <meta property="og:image" content={featuredImage} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`https://binaria.com/proyectos/${data.slug}`} />
          <meta property="twitter:title" content={yoast_meta.yoast_wpseo_title} />
          <meta property="twitter:description" content={yoast_meta.yoast_wpseo_metadesc} />
          <meta property="twitter:image" content={featuredImage}></meta>

      </Helmet>

      <ProjectHeader
        title = {header.project_header_title}
        subtitle = {header.project_header_subtitle}
        description = {header.project_header_description}
        device = {header.project_header_device_selector}
        text_color = {header.project_header_text_color}
        color_top = {header.project_header_gradient_colors.project_header_gradient_colors_top}
        color_bottom = {header.project_header_gradient_colors.project_header_gradient_colors_bottom}
        image = {header.project_header_image.localFile.childImageSharp.fluid}
        tags = {header.project_header_tags}
        link = {header.project_header_link}
        linkCondition = {header.project_header_link_condition}
      />

      <ProjectContent
        flexibleContents={content}
      />

      <ProjectNav
        path={sideMenu.primary[0].slug}
        prev={pageContext.prev}
        next={pageContext.next}
        navProjects={navProjects}
      />

      <ContactCta normal={normal} />

    </Layout>
    
  )
}

export default projectTemplate


export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpProject(id: { eq: $id }) {
      slug
      yoast_meta{
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          publicURL
        }
      }
      acf {
        project_header {
          project_header_title
          project_header_subtitle
          project_header_description
          project_header_tags {
            project_header_tags_item
          }
          project_header_link_condition
          project_header_link {
            title
            url
            target
          }
          project_header_text_color
          project_header_device_selector
          project_header_video
          project_header_image {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1280, quality: 72) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          project_header_gradient_colors {
            project_header_gradient_colors_top
            project_header_gradient_colors_bottom
          }
        }
        project_content_project{
          __typename
          ... on WordPressAcf_project_content_slider {
            project_content_slider_family
            project_content_slider_text
            project_content_slider_content {
              project_content_slider_content_selector
              project_content_slider_content_minwidth
              project_content_slider_content_gallery{
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1280, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_project_content_full_width {
            project_content_full_width_text
            project_content_full_width_text_bottom
            project_content_full_width_media {
              project_content_full_width_media_selector
              project_content_full_width_media_color
              
              project_content_full_width_media_items {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1280, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_project_content_text_media {
              project_content_text_media_family
							project_content_text_media_order_selector
              project_content_text_media_title
              project_content_text_media_description
              project_content_text_media_media {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1280, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
          }
          ... on WordPressAcf_project_content_two_text {
              project_content_two_text_family
              project_content_two_text_order_selector
              project_content_two_text_text_1
              project_content_two_text_text_2
          }
        }
      }
    },
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`