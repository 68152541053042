import React, {useState, useEffect} from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring'

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from '../../styles/device'

import CtaScrollDown from "../ctaScrollDown"

const ProjectHeaderSection =  styled.section`
    position: relative;
    overflow: hidden;
    
    background: linear-gradient(to bottom right, ${props => props.color_top}, ${props => props.color_bottom} );
    font-family: ${fonts.poppins};

    > div {
        height: 100vh;
        width: 100%;
        display: flex;

        @media ${device.tablet}{
            height: inherit;
        }
    }

    * {
        color: ${props => props.color="light" ? colors.white : colors.tertiary};
    }
`

const ProjectHeaderContainer = styled.div`
    max-width: 1440px;
    
    margin: auto;
    padding: 80px 8.3333%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.tablet}{
        padding: 240px 30px 80px;
        margin: 0 auto;
    }

    @media ${device.phone}{
        padding: 200px 20px 80px;
        min-height: inherit;
        height: inherit;
    }
`

const ProjectHeaderRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
    margin: 0 -10px;
`

const ProjectHeaderBlock = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    padding: 0 10px;
    width: 50%;

    @media ${device.tablet}{
        width: 100%;
    }
`

const ProjectHeaderSectionTitle = styled.h1`
    font-weight: bold;
    font-size: 48px;
    letter-spacing: -0.72px;
    line-height: 54px;

    margin: 0;

    @media ${device.phone}{
        font-size: 40px;
        line-height: 45px;
    }
`
const ProjectHeaderSectionSubtitle = styled.span`
    font-weight: bold;
    letter-spacing: -0.43px;
    line-height: 26px;
    font-size: 23px;
    margin-top: 20px;
`
const ProjectHeaderSectionDescription = styled.p`
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
    margin: 20px 0 0;
`
const ProjectHeaderSectionTags = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;

    width: 100%;
    max-height: 115px;
    padding: 0;
    margin: 60px -10px 0;
`
const ProjectHeaderSectionTag = styled.li`
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;

    width: 50%;
    padding: 0 10px 0;
`
const ProjectHeaderSectionLink = styled.a`
    font-size: 14px;
    text-decoration: underline;
    line-height: 21px;

    padding: 40px 0 0;
    margin-right: auto;
    max-width: 50%;
`
const ProjectHeaderSectionImg = styled(Img)`
    width: 100%;
`

const ProjectHeader = ({title, subtitle, description, device, text_color, color_top, color_bottom, image, tags, link, linkCondition}) => {

    const [windowWidth, setWindowWidth] = useState(0)

    const props = useSpring({
        from: {
            opacity: 0,
            transform: 'scale(1.2)'
        },
        to: {
            opacity: 1,
            transform: 'scale(1)'
        }
    })

    useEffect( () => {

        if(typeof window !== 'undefined') {
            setWindowWidth(window.innerWidth);
            window.addEventListener("resize", () => {
              setWindowWidth(window.innerWidth)
            })
          }

    }, [] )

    return (

        <ProjectHeaderSection color_top={color_top} color_bottom={color_bottom} color={text_color} device={device} image={image}>

            <animated.div style={props}>

                <ProjectHeaderContainer>

                    <ProjectHeaderRow>

                        <ProjectHeaderBlock>
                            
                            <ProjectHeaderSectionTitle>{title}</ProjectHeaderSectionTitle>
                            <ProjectHeaderSectionSubtitle>{subtitle}</ProjectHeaderSectionSubtitle>

                            
                            {windowWidth < deviceSize.desktop && <ProjectHeaderSectionImg fluid={image}/>}


                            <ProjectHeaderSectionDescription dangerouslySetInnerHTML={{ __html: description }}/>

                            <ProjectHeaderSectionTags>
                                
                                {tags.map((tag) => (
                                    <ProjectHeaderSectionTag key={"ProjectHeaderSectionTag" + tag.project_header_tags_item.toString()}>{tag.project_header_tags_item}</ProjectHeaderSectionTag>
                                ))}

                            </ProjectHeaderSectionTags>

                            {
                                linkCondition && <ProjectHeaderSectionLink href={link.url} target={link.target}>{link.title}</ProjectHeaderSectionLink>
                            }

                        </ProjectHeaderBlock>

                        {windowWidth >= deviceSize.desktop && 
                            <ProjectHeaderBlock>
                            
                                <ProjectHeaderSectionImg fluid={image}/>

                            </ProjectHeaderBlock>
                        }

                    </ProjectHeaderRow>

                    <CtaScrollDown target="#projectContent"/>

                </ProjectHeaderContainer>

            </animated.div>

        </ProjectHeaderSection>

    )
}

export default ProjectHeader;