import React, {useState} from "react"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device from '../../styles/device'

import ProjectContentFullWidthImgItem from "./ProjectContentFullWidthImgItem"

const ProjectContentFullWidthSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    font-family: ${fonts.poppins};
    width: 100%;
    color: ${colors.tertiary};
    
    margin-top: 80px;

    @media ${device.tablet}{
        margin-top: 60px;
    }
`

const ProjectContentFullWidthContainer = styled.div`
    max-width: 1440px;
    
    margin: auto;
    padding: 80px 8.3333%;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;

    @media ${device.tablet}{
        padding: 40px 30px;
    }

    @media ${device.phone}{
        padding: 20px 20px;
    }
`

const ProjectContentFullWidthRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    
    margin: 0 -10px;

    > div + ul,
    > ul + div {
        margin-top: 80px;

        @media ${device.phone}{
            margin-top: 40px;
        }
    }
`

const AnimatedProjectContentFullWidthTextContainer = styled.div`

`

const ProjectContentFullWidthText = styled.div`
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;

    padding: 0 10px;

    * {
        margin: 0;
    }

    h2 {
        font-size: 33px;
        font-weight: bold;
        letter-spacing: -0.71px;
        line-height: 37px;
        margin-top: 0;
    }

    h2 + p,
    p + p {
        margin-top: 24px;
    }
`

const ProjectContentFullWidthImg = styled.ul`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    
    list-style: none;
    margin: 0;
    padding: 0;
`

const ProjectContentFullWidth = ({fullWidthColor, fullWidthText, fullWidthTextBottom, fullWidthContent, fullWidthFamily}) => {

    const thrs = 0.25

    const [ref, entry] = useIntersect({
      threshold: thrs
    });

    const [refBottom, entryBottom] = useIntersect({
        threshold: thrs
      });
  
    const [show, setShow] = useState(false)
    const [showBottom, setShowBottom] = useState(false)
  
    const props = useSpring({
        from: {
            opacity: 0, 
            transform: `translateY(200px)`,
          },
          to: {
            opacity: show ? 1 : 0,
            transform: `translateY(${show ? 0 : 200}px)`
          },
          onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
    })

    const propsBottom = useSpring({
        from: {
            opacity: 0, 
            transform: `translateY(200px)`,
          },
          to: {
            opacity: showBottom ? 1 : 0,
            transform: `translateY(${showBottom ? 0 : 200}px)`
          },
          onRest: showBottom === false && entryBottom.isIntersecting === true ? setShowBottom(true) : null
    })
  
    const AnimatedProjectContentFullWidthText = animated(ProjectContentFullWidthText)
    
    return (

        <ProjectContentFullWidthSection>

            <ProjectContentFullWidthContainer>

                <ProjectContentFullWidthRow>
                

                    {fullWidthText && 
                        <AnimatedProjectContentFullWidthTextContainer ref={ref}>
                            <AnimatedProjectContentFullWidthText style={props} dangerouslySetInnerHTML={{__html: fullWidthText}} />
                        </AnimatedProjectContentFullWidthTextContainer>
                    }

                    {fullWidthContent && <ProjectContentFullWidthImg family={fullWidthFamily}>
                        {fullWidthContent && fullWidthContent.map((item, index) => (
                            <ProjectContentFullWidthImgItem key={'ProjectContentFullWidthImgItem' + index} fullWidthContent={fullWidthContent} fullWidthFamily={fullWidthFamily} item={item}/>
                        ))}
                    </ProjectContentFullWidthImg>}

                    {fullWidthTextBottom && 
                        <AnimatedProjectContentFullWidthTextContainer ref={refBottom}>
                            <AnimatedProjectContentFullWidthText style={propsBottom} dangerouslySetInnerHTML={{__html: fullWidthTextBottom}} />
                        </AnimatedProjectContentFullWidthTextContainer>
                    }

                </ProjectContentFullWidthRow>

            </ProjectContentFullWidthContainer>
            
        </ProjectContentFullWidthSection>

    )
}

export default ProjectContentFullWidth