import React, {useRef, useState} from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import redArrow from "../../images/red-arrow.svg"
import redArrowDark from "../../images/red-arrow-dark.svg"
import blueArrow from "../../images/blue-arrow.svg"
import blueArrowDark from "../../images/blue-arrow-dark.svg"

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from '../../styles/device'

import Device from "../device"

const ProjectContentSliderSection = styled.section`
    width: 100%;
    position: relative;

    margin: 80px auto 0;

    @media ${device.tablet}{
        margin: 60px auto 0;
    }

    ::before {
        content: "";
        position: absolute;
        width: 100vw;
        height: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${({sliderFamily}) => sliderFamily && sliderFamily.includes("design") ? colors.primaryLight : colors.secondaryLight };
        z-index: -1;
        
        @media ${device.phone}{
            display: none;
        }
    }
`

const ProjectContentSliderContainer = styled.div`
    font-family: ${fonts.poppins};
    position: relative;
    max-width: 1440px;
    
    padding: 80px 8.3333%;
    margin: 0 auto;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;

    @media ${device.tablet}{
        padding: 40px 30px;
    }

    @media ${device.phone}{
        padding: 20px 20px;
    }
`

const ProjectContentSliderRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    
    margin: 0 -10px;
`

const ProjectContentSliderText = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 

    padding: 0 10px;

    margin: 0 0 60px;

    @media ${device.tablet}{
        margin: 0 0 40px;
    }

    * {
        margin: 0;
    }

    h2 {
        font-size: 33px;
        font-weight: bold;
        line-height: 37px;
    }

    p {
        margin-top: 20px;
    }
`

const ProjectContentSliderMedia = styled.div`
    position: relative;
    padding: 40px 0;

    @media ${device.phone}{
        &:before {
            content: "";
            position: absolute;
            width: 200vw;
            left: -50vw;
            top: 0;
            bottom: 0;
            background-color: ${({sliderFamily}) => sliderFamily && sliderFamily.includes("design") ? colors.primaryLight : colors.secondaryLight };
            z-index: -1;
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slick-slide {
        padding: 0 10px;
        box-sizing: border-box;
    }

    .slick-arrow {
        display: none !important;
    }
`

const ProjectContentSliderNav = styled.div`
    width: 100%;
    display: flex;
    text-align: center;

    padding: 0 10px;

    margin: 60px 0 0;

    @media ${device.tablet}{
        margin: 40px 0 0;
    }

    button {
        text-indent: -9999em;
        text-transform: uppercase;
        user-select: none;
        cursor: pointer;
        border: red;
        width: 40px;
        height: 40px;
        background: url('${({sliderFamily}) => sliderFamily && sliderFamily.includes("design") ? blueArrow : redArrow }') no-repeat center center;
        transition: .2s;

        :hover {
            background: url('${({sliderFamily}) => sliderFamily && sliderFamily.includes("design") ? blueArrowDark : redArrowDark }') no-repeat center center;
        }
        
        :active, :focus {
            outline: none;
        }
    }

    .button_left {
        transform: rotate(90deg);
        margin: 0 20px 0 0;
    }

    .button_right {
        transform: rotate(-90deg);
    }

    .desactive {
      background: url('${({sliderFamily}) => sliderFamily && sliderFamily.includes("design") ? blueArrow : redArrow }') no-repeat center center;
      opacity: .5;
    }
`

const ProjectContentSlider = ({sliderText, sliderGallery, sliderFamily, sliderSelector}) => {

    const SliderRef = useRef(null)

    const next = () => {
        SliderRef.current.slickNext();
      }

    const previous = () => {
        SliderRef.current.slickPrev();
      }
    const handleChangeSlide = (i) => {
        i === 0 ? document.querySelector('.button_left').classList.add('desactive') : document.querySelector('.button_left').classList.remove('desactive')
        window.innerWidth >= deviceSize.tablet ? (
            i === sliderGallery.length - SliderRef.current.props.slidesToShow ? document.querySelector('.button_right').classList.add('desactive') : document.querySelector('.button_right').classList.remove('desactive')
          ) : window.innerWidth >= deviceSize.phone ? (
            i === sliderGallery.length - SliderRef.current.props.responsive[0].settings.slidesToShow ? document.querySelector('.button_right').classList.add('desactive') : document.querySelector('.button_right').classList.remove('desactive')
          ) : (
            i === sliderGallery.length - SliderRef.current.props.responsive[1].settings.slidesToShow ? document.querySelector('.button_right').classList.add('desactive') : document.querySelector('.button_right').classList.remove('desactive')
          )
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: sliderSelector === "mobile" ? 3 : sliderSelector === "large" ? 1 : 2 ,
        slidesToScroll: 1,
        afterChange: (current) => handleChangeSlide(current),
        responsive: [
            {
              breakpoint: deviceSize.tablet,
              settings: {
                slidesToShow: sliderSelector === "mobile" ? 2  : 1,
                slidesToScroll: 1,
                speed: 300,
              }
            },
            {
                breakpoint: deviceSize.phone,
                settings: {
                  slidesToShow: 1 ,
                  slidesToScroll: 1,
                  speed: 300,
                }
              }
        ]
      }


      const thrs = 0.25

      const [ref, entry] = useIntersect({
        threshold: thrs
      });
    
      const [show, setShow] = useState(false)
    
      const props = useSpring({
        from: {
          opacity: 0, 
          transform: `translateX(200px)`
        },
        to: {
          opacity: show ? 1 : 0,
          transform: `translateX(${show ? 0 : 200}px)`
        },
        onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
      })

      const AnimatedProjectContentSliderContainer = animated(ProjectContentSliderContainer)

     return (

        <ProjectContentSliderSection ref={ref} sliderFamily={sliderFamily}>
            <AnimatedProjectContentSliderContainer style={props}>

                <ProjectContentSliderRow>

                    <ProjectContentSliderText dangerouslySetInnerHTML={{__html: sliderText}} />

                    <ProjectContentSliderMedia sliderFamily={sliderFamily}>

                        
                        <Slider ref={SliderRef} {...settings}>
                            {sliderGallery.map((slide, index) => ( 
                                <Device key={'ProjectContentSlide' + index} imageData={slide} deviceType={sliderSelector} />
                            ))}
                        </Slider>


                        <ProjectContentSliderNav sliderFamily={sliderFamily}>
                            <button className="desactive button_left" onClick={previous} />
                            <button className="button_right" onClick={next} />
                        </ProjectContentSliderNav>

                    </ProjectContentSliderMedia>

                </ProjectContentSliderRow>

            </AnimatedProjectContentSliderContainer>
        </ProjectContentSliderSection>
    )
}

export default ProjectContentSlider