import React, {useState, useEffect} from "react"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import Device from "../device"

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from '../../styles/device'



const ProjectContentTextMediaSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    width: 100%;
    position: relative;
    font-family: ${fonts.poppins};

    margin-top: 80px;

    @media ${device.tablet}{
        margin-top: 60px;
    }
`

const ProjectContentTextMediaBackground = styled.div`
    position: absolute;
    width: 41.6666%;
    height: 100%;
    left: ${({order}) => order === "image_text" ? "0" : "inherit" };
    right: ${({order}) => order === "image_text" ? "inherit" : "0" };
    background-color: ${({family}) => family.includes("design") ? colors.primaryLight : colors.secondaryLight};
    z-index: -1;

    @media ${device.tablet}{
        width: 100%;
        left: 0;
        right: 0;
    }
`

const ProjectContentTextMediaContainer = styled.div`
    max-width: 1440px;
    
    margin: auto;
    padding: 80px 8.3333%;

    @media ${device.tablet}{
        padding: 40px 30px 0;
    }

    @media ${device.phone}{
        padding: 40px 20px 0;
    }
`

const ProjectContentTextMediaRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    
    margin: 0 -10px;

    @media ${device.tablet}{
        flex-direction: column;
    }
`

const ProjectContentTextTextsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    order: ${({order}) => order  === "image_text" ? "2" : "1" };
    
    padding: 0 10px;


    @media ${device.tablet}{
        width: inherit;
    }
`

const ProjectContentTextMediaTitle = styled.h2`
    font-size: 33px;
    font-weight: bold;
    letter-spacing: -0.71px;
    line-height: 37px;
    margin: 0;
    color: ${colors.tertiary};

    padding: ${({order}) => order === "image_text" ? "0 0 0 50px" : "0 50px 0 0" };

    @media ${device.tablet}{
        order: 1;
        width: inherit;
        padding: 0;
        text-align: left;
    }

    @media ${device.phone}{
        margin-right: auto;
    }
`

const ProjectContentTextMediaDescription = styled.div`
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
    color: ${colors.tertiary};

    padding: ${({order}) => order === "image_text" ? "0 0 0 50px" : "0 50px 0 0" };
    margin: 24px 0 0;

    p {
        margin: 0;
    }

    @media ${device.tablet}{
        order: 3;
        width: inherit;
        padding: 0;
    }

    @media ${device.phone}{
        order: 2;
    }
`

const ProjectContentTextMediaMedia = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 50%;

    order: ${({order}) => order  === "image_text" ? "1" : "2" };

    padding: 0 10px;

    @media ${device.tablet}{
        order: 2;
        width: inherit;
        height: auto;
        padding: 40px 30px;

        margin: 40px 10px 0;

        ::before {
            content: "";
            position: absolute;
            background-color: ${({family}) => family.includes("design") ? colors.primaryLight : colors.secondaryLight};
            top: 0;
            left: 0;
            z-index: -1;
    
            @media ${device.tablet}{
                display: none;
            }
        }
    }

    @media ${device.phone}{
        order: 3;
        margin: 40px -10px 0;
        padding: 40px 20px;
    }
`
const ProjectContentTextMediaMediaBlock = styled.div`
    width: 100%;
`

const ProjectContentTextMedia = ({textMediaOrder, textMediaFamily, textMediaTitle, textMediaDescription, textMediaMedia}) => {

    const [windowWidth, setWindowWidth] = useState(0)

    const thrs = 0.25

    const [ref, entry] = useIntersect({
      threshold: thrs
    });
  
    const [show, setShow] = useState(false)
  
    const props = useSpring({
        from: {
            opacity: 0, 
            transform: `translateY(200px)`,
          },
          to: {
            opacity: show ? 1 : 0,
            transform: `translateY(${show ? 0 : 200}px)`
          },
          onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
    })

    const AnimatedProjectContentTextMediaContainer = animated(ProjectContentTextMediaContainer)

    const propsBackground = useSpring({
        from: {
            opacity: 0, 
            transform: `translateX(${ textMediaOrder === "image_text" ? '-200px' : '200px' })`,
          },
          to: {
            opacity: show ? 1 : 0,
            transform: `translateX(${show ? 0 : textMediaOrder === "image_text" ? '-200px' : '200px'}px)`
          },
          onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
    })

    const AnimatedProjectContentTextMediaBackground = animated(ProjectContentTextMediaBackground)

    useEffect( () => {

        if(typeof window !== 'undefined') {
            setWindowWidth(window.innerWidth);
            window.addEventListener("resize", () => {
              setWindowWidth(window.innerWidth)
            })
          }

    }, [] )

    return (

        <ProjectContentTextMediaSection ref={ref} order={textMediaOrder} family={textMediaFamily}>

            {windowWidth >= deviceSize.desktop && <AnimatedProjectContentTextMediaBackground order={textMediaOrder} family={textMediaFamily} style={propsBackground} />}
        
            <AnimatedProjectContentTextMediaContainer style={props}>

                <ProjectContentTextMediaRow>
                
                    <ProjectContentTextTextsContainer order={textMediaOrder}>
                        <ProjectContentTextMediaTitle dangerouslySetInnerHTML={{__html: textMediaTitle}} order={textMediaOrder}/>
                        <ProjectContentTextMediaDescription dangerouslySetInnerHTML={{__html: textMediaDescription}} order={textMediaOrder} />
                    </ProjectContentTextTextsContainer>

                    <ProjectContentTextMediaMedia order={textMediaOrder} family={textMediaFamily}>

                        {windowWidth < deviceSize.desktop && <AnimatedProjectContentTextMediaBackground order={textMediaOrder} family={textMediaFamily} style={propsBackground} />}

                        <ProjectContentTextMediaMediaBlock>
                            <Device imageData={textMediaMedia} deviceType="window" />
                        </ProjectContentTextMediaMediaBlock>
                    </ProjectContentTextMediaMedia>

                </ProjectContentTextMediaRow>

            </AnimatedProjectContentTextMediaContainer>

        </ProjectContentTextMediaSection>

    )
}

export default ProjectContentTextMedia